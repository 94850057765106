@import 'bootstrap/bootstrap';
@import 'bootstrap/variables';

$font-primary: 'Poppins',Arial, sans-serif;

$color3: #fff;
$black: #000000;
$semone:#519188;
$color1:#d5b9b1;
$color2:#f2d6ca;
$color3:#f4dbc9;
// $darken: #3a4348;

$primary: #aa706a;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

@mixin transition($transition) {
    -moz-transition:    all $transition ease;
    -o-transition:      all $transition ease;
    -webkit-transition: all $transition ease;
    -ms-transition: 		all $transition ease;
    transition:         all $transition ease;
}

html {
	// overflow-x: hidden;
}
body {
	font-family: $font-primary;
	background: $white;
	font-size: 16px;
	line-height: 1.8;
	font-weight: 300;
	color: lighten($black,40%);
	&.menu-show {
		overflow: hidden;
		position: fixed;
		height: 100%;
		width: 100%;
	}
}
a {
	transition: .3s all ease;
	color: $primary;
	&:hover, &:focus {
		text-decoration: none;
		color: $primary;
		outline: none !important;
	}
}
h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
	line-height: 1.5;
	color: rgba(0,0,0,.8);
	font-weight: 400;
}

.text-primary {
	color: $primary!important;
}

.ftco-navbar-light {
	background: white !important;
	 position: absolute;
	 top: 0px;
	 width: 100%;
	// left: 0;
	// right: 0;
	z-index: 30;
	padding: 0;
	@include media-breakpoint-down(md) {
		background: $white!important;
		position: absolute;
		top: 0;
		padding: 10px 15px;
	}
	&.ftco-navbar-light-2{
		position: absolute;
		top: 0;
	}

	.navbar-brand {
		color: $black;
		&:hover, &:focus{
			color: $black;
		}
		img{
			height: 30px;
			width: 146px;
		}
		@include media-breakpoint-down(md){
			color: $white;
		}
	}

	.navbar-nav {
		@include media-breakpoint-down(md){
			padding-bottom: 10px;
		}
		> .nav-item {
			> .nav-link {
				font-size: 16px;
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;
				padding-left: 20px;
				padding-right: 20px;
				font-weight: 400;
				color: $black;
				&:hover {
					color: $semone;
				}
				opacity: 1!important;
				@include media-breakpoint-down(md){
					padding-left: 0;
					padding-right: 0;
					padding-top: .9rem;
					padding-bottom: .9rem;
					color: $black;
					&:hover{
						color: $semone;
					}
				}
			}

			.dropdown-menu{
				border: none;
				background: $color3;
				-webkit-box-shadow: 0px 10px 34px -20px rgba(0,0,0,0.41);
				-moz-box-shadow: 0px 10px 34px -20px rgba(0,0,0,0.41);
				box-shadow: 0px 10px 34px -20px rgba(0,0,0,0.41);
				.dropdown-item{
					font-size: 14px;
					&:hover, &:focus{
						background: transparent;
						color: $color2;
					}
				}
			}

			
			&.ftco-seperator {
				position: relative;
				margin-left: 20px;
				padding-left: 20px;
				@include media-breakpoint-down(md) {
					padding-left: 0;
					margin-left: 0;
				}
				&:before {
					position: absolute;
					content: "";
					top: 10px;
					bottom: 10px;
					left: 0;
					width: 2px;
					background: rgba($color3, .05);
					@include media-breakpoint-down(md) {
						display: none;
					}
				}
			}
			&.cta {
				> a {
					color: $black;
					@include media-breakpoint-down(sm){
						padding-left: 15px;
						padding-right: 15px;
					}
					@include media-breakpoint-down(md){
						color: $color3;
						background: $primary;
					}
				}
			}
			& >.active {
				
					color: $primary;
					@include media-breakpoint-down(md){
						color: $primary;
					}
				
			}
		}
	}
	
	.navbar-toggler {
		border: none;
		color: rgba(0, 0, 0, 0.5)!important;
		cursor: pointer;
		padding-right: 0;
		text-transform: uppercase;
		font-size: 16px;
		letter-spacing: .1em;
		&:focus{
			outline: none !important;
		}
	}
	
	&.scrolled  {
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		margin-top: -130px;
		background: $white!important;
		box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
		.nav-item {
			&.active {
				> a {
					color: $primary!important;
				}
			}
			&.cta {
				> a {
					color: $white !important;
					background: $primary;
					border: none !important;
					 
					span {
						display: inline-block;
						color: $white !important;
					}
				}
				&.cta-colored {
					span {
						border-color: $primary;
					}
				}
			}
		}

		.navbar-nav {
			@include media-breakpoint-down(md) {
				background: none;
				border-radius: 0px;
				padding-left: 0rem!important;
				padding-right: 0rem!important;
			}
		}

		.navbar-nav {
			@include media-breakpoint-down(sm) {
				background: none;
				padding-left: 0!important;
				padding-right: 0!important;
			}
		}

		.navbar-toggler {
			border: none;
			color: rgba(0,0,0,.5)!important;
			border-color: rgba(0,0,0,.5)!important;
			cursor: pointer;
			padding-right: 0;
			text-transform: uppercase;
			font-size: 16px;
			letter-spacing: .1em;

		}
		.nav-link {
			padding-top: .9rem!important;
			padding-bottom: .9rem!important;
			color: $black!important;
			&.active {
				color: $primary!important;
			}
		}
		&.awake {
			margin-top: 0px;
			transition: .3s all ease-out;
		}
		&.sleep {
			transition: .3s all ease-out;	
		}

		.navbar-brand {
			color: $black;
		}
	}
}

.navbar-brand {
	font-weight: 900;
	font-size: 20px;
	// text-transform: uppercase;
	// letter-spacing: 2px;
}




.hero-wrap{
	width: 100%;
	height: 700px;
	position: inherit;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
	position: relative;
	@include media-breakpoint-down(md){
		background-position: top center !important;
	}
	.overlay{
		position: absolute;
		// width: 50%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		opacity: .8;
		background: $primary;
	}
	&.hero-wrap-2{
		height: 400px;
		.slider-text{
			height: 400px;
			position: absolute;
			text-align: center;
			margin: 0px;
			width: 97%;
			.bread{
				font-weight: 900;
				color: $white;
			}
			.breadcrumbs{
				text-transform: uppercase;
				font-size: 13px;
				letter-spacing: 1px;
				span{
					color: $white;
					border-bottom: 2px solid rgba(255,255,255,.1);
					a{
						color: $white;
					}
				}
			}
		}
	}
}

//OWL CAROUSEL

.container{
	max-width: 1300px;
}

.bg-light {
	background: #fafafa!important;
}

.bg-white{
	background: $white !important;
}

.bg-primary{
	background: $primary;
}

.ftc-no-pb{
	padding-bottom: 0 !important;
}

//BUTTON
.btn {
	cursor: pointer;
	@include border-radius(30px);
	box-shadow: none!important;
	&:hover, &:active, &:focus {
		outline: none;
	}
	&.btn-primary {
		background: $primary;
		border: 1px solid $primary;
		color: $white;
		&:hover {
			border: 1px solid $primary;
			background: transparent;
			color :$primary;
		}
		&.btn-outline-primary {
			border: 1px solid $primary;
			background: transparent;
			color :$primary;
			&:hover {
				border: 1px solid transparent;
				background: $primary;
				color :$white;
			}
		}
	}
	&.btn-white {
		background: $white;
		border: 1px solid $white;
		color: $black;
		&:hover, &:focus, &:active {
			border: 1px solid $white;
			color :$primary;
		}
		&.btn-outline-white {
			border-color: rgba($white, .8);
			background: none;
			@include border-radius(30px);
			border-width: 1px;
			color: $white;
			&:hover, &:focus, &:active {
				background: $white;
				border-color: $white;
				color: $primary;
			}
		}
	}
}


.btn-custom{
	font-weight: 500;
	color: darken($primary,10%);
	border-bottom: 4px solid lighten($primary,4%);
}


//SERVICES
.ftco-services{
	// margin-top: -50px;
	padding: 5em 0;
	.services{
		display: block;
		width: 100%;
		position: relative;
		@include transition(.3s);
		&:after{
			position: absolute;
			top: 50px;
			left: 0;
			right: 0;
			content: '';
			width: 100%;
			height: 1px;
			background: lighten($primary,55%);
			z-index: -1;
		}
		
		&:before{
			position: absolute;
			top: 45px;
			right: 0;
			content: '';
			z-index: 1;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 0 5px 10px;
			border-color: transparent transparent transparent lighten($primary,55%);
		}
		.icon{
			line-height: 1.3;
			position: relative;
			width: 60px;
			height: 60px;
			// background: lighten($primary,55%);
			background: $primary;
			@include border-radius(50%);
			svg,span{
				font-size:30px;
				color: $white;
			}
		}
		.media-body{
			h3{
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 18px;
			}
		}
		&.services-2{
			&:after, &:before{
				opacity: 0;
			}
			.icon{
				line-height: 1.3;
				position: relative;
				width: 52px;
				height: 52px;
				// background: lighten($primary,55%);
				background: transparent;
				@include border-radius(50%);
				span,svg{
					font-size:40px;
					color: $primary;
				}
			}
		}
	}
}

//ABOUT
.img-2{
	@include media-breakpoint-down(sm){
		height: 300px;
		margin-bottom: 40px;
	}
}
.wrap-about{
	position: relative;
	@include media-breakpoint-up(md){
		padding-left: 6em;
	}
}

//PROJECT
.project{
	position: relative;
	width: 100%;
	display: block;
	overflow: hidden;
	z-index: 0;
	&:after{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		background: $primary;
		opacity: 0;
		z-index: 1;
		@include transition(.3s);
	}
	img{
		-webkit-transform: scale(1.0);
		-moz-transform: scale(1.0);
		-o-transform: scale(1.0);
		transform: scale(1.0);
		width: 100%;
		height: 350px;
		z-index: -1;
		@include transition(.6s);
	}
	.text{
		position: absolute;
		top: -100px;
		left: 0;
		right: 0;
		padding: 2em;
		opacity: 0;
		z-index: 2;
		@include transition(.6s);
		span{
			font-size: 14px;
			text-transform: uppercase;
			letter-spacing: 2px;
			color: rgba(255,255,255,.8);
		}
		h3{
			color: $white;
			a{
				color: $white;
			}
		}
	}
	.icon{
		position: absolute;
		bottom: -50px;
		left: 2em;
		// right: 0;
		width: 50px;
		height: 50px;
		// margin: 0 auto;
		// background: $primary;
		bordeR: 1px solid $white;
		transform: translateY(-50%);
		@include border-radius(50%);
		@include transition(.6s);
		opacity: 0;
		z-index: 2;
		span{
			color: $white;
			font-size: 20px;
		}
	}
	&:hover, &:focus{
		&:after{
			opacity: .8;
		}
		.text{
			opacity: 1;
			top: 0;
		}
		.icon{
			bottom: 10px;
			opacity: 1;
			// width: 140px;
			// height: 140px;
		}
		img{
			-webkit-transform: scale(1.2);
			-moz-transform: scale(1.3);
			-o-transform: scale(1.3);
			transform: scale(1.3);
		}
	}
}

//STAFF
.staff{
	@include transition(.3s);
	.img{
		height: 320px;
		background-position: top center;
	}
	.text{
		background: $white;
		@include transition(.3s);
		h3{
			font-size: 20px;
			margin-bottom: 0;
		}
		.position{
			color: $primary;
			display: block;
		}
		.faded{
			opacity: 0;
			@include transition(.3s);
			@include media-breakpoint-down(md){
				opacity: 1;
			}
		}
	}
	&:hover, &:focus{
		// margin-top: -50px;
		.text{
			margin-top: -50px;
			.faded{
				opacity: 1;
			}
		}
	}
}

.ftco-social{
	padding: 0;
	li{
		list-style: none;
		margin-right: 10px;
	}
}

//BLOG ENTRY
.block-20 {
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 400px;
	position: relative;
	display: block;
}
.blog-entry{
	@include media-breakpoint-up(md){
		margin-bottom: 30px;
	}
	@include media-breakpoint-down(sm){
		margin-bottom: 30px;
	}
	.text {
		position: relative;
		border-top: 0;
		border-radius: 2px;
		.desc{
			width: calc(100% - 100px);
		}
		.heading {
			font-size: 20px;
			margin-bottom: 16px;
			a {
				color: $black;
				&:hover, &:focus, &:active {
					color: $primary;
				}
			}
		}
		.meta-chat{
			color: lighten($black, 70%);
		}
		.read{
			color: $black;
			font-size: 14px;
		}
	}
	.meta {
		width: 100px;
		text-align: right;
		> div {
			display: block;
			margin-right: 5px;
			margin-bottom: 0px;
			font-size: 15px;
			a {
				color: lighten($black, 70%);
				font-size: 13px;
				&:hover {
					color: lighten($black, 80%);
				}
			}
		}
	}
}


//PAGINATION
.block-27 {
	ul {
		padding: 0;
		margin: 0;
		li {
			display: inline-block;
			margin-bottom: 4px;
			font-weight: 400;
			a,span {
				border: 1px solid $primary;
				text-align: center;
				display: inline-block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				border-radius: 50%;
			}
			&.active {
				a, span {
					background: $primary;
					color: $white;
					border: 1px solid transparent;
				}
			}
		}
	}
}













// USEFUL CODE
.aside-stretch{
	background: lighten($primary,10%);
	&:after{
		position: absolute;
		top: 0;
		right: 100%;
		bottom: 0;
		content: '';
		width: 360%;
		background: lighten($primary,10%);
		// background: #333644;
	}
	@include media-breakpoint-down(sm){
		background: transparent;
		&:after{
			background: transparent;
			display: none;
		}
	}
}


.form-control {
	height: 52px!important;
	background: $white!important;
	color: $black!important;
	font-size: 18px;
	border-radius: 0px;
	box-shadow: none!important;
	border: 1px solid rgba(0,0,0,.1);
	&:focus, &:active {
		border-color: $black;
	}
}
textarea.form-control {
	height: inherit!important;
}

.ftco-animate {
	opacity: 0;
	visibility: hidden;
}

.bg-primary {
	background: $primary!important;
}


.about-author{
	img{
	}
	.desc{
		h3{
			font-size: 24px;
		}
	}
	.bio{

	}
}


.ftco-section {
	padding: 7em 0;
	position: relative;
	overflow: hidden;
}

.ftco-no-pt{
	padding-top: 0;
}
.ftco-no-pb{
	padding-bottom: 0;
}

.ftco-bg-dark {
	background: #3c312e;
}


.ftco-footer {
	font-size: 14px;
	background: #141313;
	padding: 6em 0;
	overflow: hidden;
	.ftco-footer-logo {
		text-transform: uppercase;
		letter-spacing: .1em;
	}
	.ftco-footer-widget {
		h2 {
			font-weight: normal;
			color: $white;
			margin-bottom: 40px;
			font-size: 18px;
			font-weight: 400;
		}
		ul{
			li{
				a{
					span{
						color: $white;
					}
				}
			}
		}
		.btn-primary{
			background: $white !important;
			border: 2px solid $white !important;
			&:hover{
				background: $white;
				border: 2px solid $white !important;
			}
		}
	}
	p {
		color: rgba($white, .7);
	}
	a {
		color: rgba($white, .7);
		&:hover {
			color: $primary;
		}
	}
	.ftco-heading-2 {
		font-size: 17px;
		font-weight: 400;
		color: $black;
	}

	.categories{
		li{
			border-bottom: 1px solid rgba(255,255,255,.1);
			a{
				span{
					color: rgba(255,255,255,.5) !important;
				}
			}
		}
	}
	.subscribe-form{
		.form-group {
			position: relative;
			margin-bottom: 0;
			@include border-radius(0);
			input {
				@include border-radius(2px);
				width: 100%;
				background: rgba(255,255,255,.1) !important;
				border: none;
				color: rgba(255,255,255,.7) !important;
				font-size: 16px;
				&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
				  color: rgba(255,255,255,.7) !important;
				}
				&::-moz-placeholder { /* Firefox 19+ */
				  color: rgba(255,255,255,.7) !important;
				}
				&:-ms-input-placeholder { /* IE 0+ */
				  color: rgba(255,255,255,.7) !important;
				}
				&:-moz-placeholder { /* Firefox 18- */
				  color: rgba(255,255,255,.7) !important;
				}
			}
			.submit{
				color: $white !important;
				font-size: 16px;
				background: $primary !important;
				border: none !important;
				@include border-radius(2px);
				&:hover{
					cursor: pointer;
				}
			}
		}
		.icon {
			position: absolute;
			top: 50%;
			right: 20px;
			transform: translateY(-50%);
			color: rgba(255,255,255,.8);
		}
	}
}


.ftco-footer-social {
	li {
		list-style: none;
		margin: 0 10px 0 0;
		display: inline-block;
		a {
			height: 40px;
			width: 40px;
			display: block;
			float: left;
			background: rgba($color3, .05);
			border-radius: 50%;
			position: relative;
			svg,span {
				position: absolute;
				font-size: 20px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
			&:hover {
				color: $color3;
			}
		}
	}
}
.footer-small-nav {
	> li {
		display: inline-block;
		a {
			margin: 0 10px 10px 0;
			&:hover, &:focus {
				color: $primary;
			}
		}
	}
}



// Map

#map {
	// height: 400px;
	width: 100%;
	@include media-breakpoint-down(sm) {
		height: 300px;
	}
}


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($primary, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 30px rgba($primary, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($primary, 0.4);
    box-shadow: 0 0 0 0 rgba($primary, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 30px rgba($primary, 0);
      box-shadow: 0 0 0 30px rgba($primary, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba($primary, 0);
      box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}

.heading-section{
	.subheading{
		font-weight: 400;
		font-size: 18px;
		display: block;
		margin-bottom: 0;
		text-transform: uppercase;
		letter-spacing: 2px;
		// color: lighten($black,70%);
		color: $black;
		position: relative;
		&.subheading-with-line{
			small{
				// background: $white;
			}
			&:after{
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				width: 100%;
				height: 1px;
				content: '';
				// background: rgba(0,0,0,.1);
				background: lighten($primary,55%);
				z-index: -1;
			}
		}
	}
	h2{
		font-size: 28px;
		font-weight: 500;
		// text-transform: uppercase;
		@include media-breakpoint-down(sm){
			font-size: 28px;
		}
	}
	&.heading-section-with-line{
		position: relative;
		&:after{
			position: absolute;
			bottom: -20px;
			left: 0;
			width: 100%;
			height: 1px;
			content: '';
			background: $primary;
		}
	}
	&.heading-section-white{
		.subheading{
			color: rgba(255,255,255,.7);
		}
		h2{
			font-size: 40px;
			color: $white;
		}
		p{
			color: rgba(255,255,255,.9);
		}
	}
	.category{
		text-transform: uppercase;
		font-weight: 300;
		font-size: 12px;
		letter-spacing: 2px;
		span{
			a{
				color: $black;
			}
			&.active{
				font-weight: 500;
			}
		}
	}
}
.border-line{
	position: relative;
	p{
		color: rgba(0,0,0,.5);
	}
}

//COVER BG
.hotel-img,
.img,
.blog-img,
.user-img{
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}





//TESTIMONY
.testimony-section, .ftco-client{
	position: relative;
	.owl-carousel{
		margin: 0;
	}
	.owl-carousel .owl-stage-outer{
		padding-top: 3.5em;
		padding-bottom: 2em;
		position: relative;
	}
	.owl-nav {
		position: absolute;
		top: 100%;
		width: 100%;
		.owl-prev,
		.owl-next {
			position: absolute;
			transform: translateY(-50%);
			margin-top: -10px;
			outline: none !important;
			@include transition(.3s);
			span {
				&:before {
					font-size: 30px;
					color: rgba(0,0,0,.2);
					@include transition(.3s);
				}
			}
			&:hover,&:focus{
				span{
					&:before{
						color: $black;
					}
				}
			}
			opacity: 0;
		}
		.owl-prev {
			left: 50%;
			margin-left: -80px;
		}
		.owl-next {
			right: 50%;
			margin-right: -80px;
		}
	}
	&:hover{
		.owl-nav{
			.owl-prev,
			.owl-next{
				opacity: 1;
			}
			.owl-prev {
				left: 50%;
				margin-left: -80px;
			}
			.owl-next {
				right: 50%;
				margin-right: -80px;
			}
		}
	}
	.owl-dots {
		text-align: center;
		.owl-dot {
			width: 10px;
			height: 10px;
			margin: 5px;
			border-radius: 50%;
			background: rgba(0,0,0,.2);
			&.active {
				background: $primary;
			}
		}
	}
}
.testimony-wrap{
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: start;
	height: 400px;
	margin: 5px;
	
		background: rgba(255,255,255,.1);
		color: rgba(0,0,0,.8);
		.user-img{
			width: 100px;
			height: 100px;
			position: relative;
			
			
			.quote{
				position: absolute;
				bottom: -10px;
				right: 0;
				width: 40px;
				height: 40px;
				background: $color3;
				@include border-radius(50%);
				i{
					color: $primary;
				}
			}
		}
		.name{
			font-weight: 400;
			font-size: 18px;
			margin-bottom: 0;
			color: $black;
		}
		.position{
			font-size: 13px;
		}
		.line{
			position: relative;
			border-left: 1px solid lighten($black,90%);
			&:after{
				position: absolute;
				top: 50%;
				left: -2px;
				transform: translateY(-50%);
				content: '';
				width: 3px;
				height: 30px;
				background: $primary;
			}
		}
	}

.ftco-client{
	position: relative;
	.owl-carousel{
		margin: 0;
	}
	.owl-carousel .owl-stage-outer{
		padding-top: 0;
		padding-bottom: 2em;
		position: relative;
	}
}
//CLIENT
.client{
	border: 1px solid lighten($black,94%);
	display: block;
	color: $black;
	font-weight: 700;
	text-transform: uppercase;
	&:hover{
		-webkit-box-shadow: 0px 0px 39px -17px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 39px -17px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 39px -17px rgba(0,0,0,0.75);
	}
}


.about-image{
	@include media-breakpoint-down(sm){
		height: 400px;
		margin-bottom: 30px;
	}
}


// magnific pop up

.image-popup {
	cursor: -webkit-zoom-in;
	cursor: -moz-zoom-in;
	cursor: zoom-in;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out; 
  -moz-transition: all 0.3s ease-out; 
  -o-transition: all 0.3s ease-out; 
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container, 
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}



#section-counter{
	position: relative;
	z-index: 0;
	&:after{
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: '';
		z-index: -1;
		opacity: .8;
		background: $primary;
	}
}
.ftco-counter {
	padding: 6em 0;
	@include media-breakpoint-down(lg){
		background-position: center center !important;
	}
	.icon{
		span{
		}
	}
	.block-18{
		display: block;
		width:100%;
		text-align: center;
	}
	.text{
		strong.number{
			font-weight: 500;
			font-size: 40px;
			color: $white;
			display: block;
		}
		span{
			display: block;
			font-size: 16px;
			color: rgba(255,255,255,.8);
		}
	}
	.counter-wrap{
		@include media-breakpoint-down(sm){
			margin-bottom: 20px;
		}
	}
	.ftco-number {
		display: block;
		font-size: 72px;
		font-weight: bold;
		color: $primary;
	}
	.ftco-label {
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: .1em;
	}
}





.block-23 {
	ul {
		padding: 0;
		li {
			
			&, > a {
				display: table;
				line-height: 1.5;
				margin-bottom: 15px;
			}
			span{
				color: rgba($white, .7);
			}
			.icon, .text {
				display: table-cell;
				vertical-align: top;
			}
			.icon {
				width: 40px;
				font-size: 18px;
				padding-top: 2px;
				color: rgba($white, 1);
			}
			
		}
	}
}

//### .block-4
.contact-section {
	.contact-info{
		p{
			a{
				color: lighten($black,10%);
			}
			span{}
		}
	}
}
.block-9 {
	.form-control {
		outline: none!important;
		box-shadow: none!important;
		font-size: 15px;
	}
	#map {
	}
}


//### .block-21
.block-21 {
	.blog-img{
		display: block;
		height: 80px;
		width: 80px;
	}
	.text {
		width: calc(100% - 100px);
		.heading {
			font-size: 18px;
			font-weight: 300;
			a {
				color: $black;
				&:hover, &:active, &:focus {
					color: $primary;
				}
			}
		}
		.meta {
			> div {
				display: inline-block;
				font-size: 12px;
				margin-right: 5px;
				a {
					color: lighten($black, 50%);
				}
			}
		}
	}
}


/* Blog*/
.post-info {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	color: $white;
	letter-spacing: .1em;
	> div {
		display: inline-block;

		.seperator {
			display: inline-block;
			margin: 0 10px;
			opacity: .5;
		}
	}
}

.tagcloud {
	a {
		text-transform: uppercase;
		display: inline-block;
		padding: 4px 10px;
		margin-bottom: 7px;
		margin-right: 4px;
		border-radius: 4px;
		color: $black;
		border: 1px solid #ccc;
		font-size :11px;
		&:hover {
			border: 1px solid #000;
		}
	}
}

.comment-form-wrap {
	clear: both;
}

.comment-list {
	padding: 0;
	margin: 0;
	.children {
		padding: 50px 0 0 40px;
		margin: 0;
		float: left;
		width: 100%;
	}
	li {
		padding: 0;
		margin: 0 0 30px 0;
		float: left;
		width: 100%;
		clear: both;
		list-style: none;
		.vcard {
			width: 80px;
			float: left;
			img {
				width: 50px;
				border-radius: 50%;
			}
		}
		.comment-body {
			float: right;
			width: calc(100% - 80px);
			h3 {
				font-size: 18px;
				font-weight: 500;
			}
			.meta {
				text-transform: uppercase;
				font-size: 13px;
				letter-spacing: .1em;
				color: #ccc;
			}
			.reply {
				padding: 5px 10px;
				background: lighten($black, 90%);
				color: $black;
				text-transform: uppercase;
				font-size: 11px;
				letter-spacing: .1em;
				font-weight: 400;
				border-radius: 4px;
				&:hover {
					color: $white;
					background: lighten($black, 0%);
				}
			}
		}
	}
}

.search-form {
	background: lighten($black, 98%);
	padding: 10px;

	.form-group {
		position: relative;
		margin-bottom: 0;
		input {
			padding-right: 50px;
			font-size: 14px;
		}
	}
	.icon {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}
}

.subs-wrap{
	background: $primary !important;
	display: block;
	width: 100%;
	color: rgba(255,255,255,.7);
	h3{
		color: $white;
		font-size: 30px !important;
	}
}
.subscribe-form{
	.form-group {
		position: relative;
		margin-bottom: 0;
		@include border-radius(0);
		input {
			background: transparent !important;
			border: 1px solid transparent;
			color: rgba(255,255,255,.7) !important;
			font-size: 16px;
			@include border-radius(0);
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: rgba(255,255,255,.7) !important;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: rgba(255,255,255,.7) !important;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: rgba(255,255,255,.7) !important;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: rgba(255,255,255,.7) !important;
			}
		}
		.form-control{
			border: 1px solid rgba(255,255,255,.4);
		}
		.submit{
			color: $black !important;
			display: block;
			width: 100%;
			height: 52px;
			font-size: 16px;
			background: $white !important;
			@include border-radius(0);
			&:hover{
				cursor: pointer;
			}
		}
	}
	.icon {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		color: rgba(255,255,255,.8);
	}
}

// sidebar
.sidebar{
	@include media-breakpoint-down(md){
		margin-top: 5em;
	}
}
.sidebar-box {
	margin-bottom: 30px;
	padding: 0 25px 25px;
	font-size: 15px;
	width: 100%;
	h3 {
		font-size: 18px;
		margin-bottom: 20px;
	}
}

.categories, .sidelink {
	padding: 0;
	li {
		position: relative;
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid gray('300');
		list-style: none;
		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
			padding-bottom: 0;
		}
		a {
			display: block;
			span {
				position: absolute;
				right: 0;
				top: 0;
				color: #ccc;
			}
		}
		&.active {
			a {
				color: $black;
				font-style: italic;
			}
		}
	}
}



#ftco-loader {
	position: fixed;
	width:  96px;
	height: 96px;
	left:  50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(255,255,255,0.9);
	box-shadow: 0px 24px 64px rgba(0,0,0,0.24);
	border-radius:16px;
	opacity: 0; 
	visibility: hidden;
	transition: opacity .2s ease-out, visibility 0s linear .2s;
	z-index:1000;
}

#ftco-loader.fullscreen {
	padding:  0;
	left:  0;
	top:  0;
	width:  100%;
	height: 100%;
	transform: none;
	background-color: #fff;
	border-radius: 0;
	box-shadow: none;
}

#ftco-loader.show {
	transition: opacity .4s ease-out, visibility 0s linear 0s;
	visibility: visible;
	opacity: 1;
}

#ftco-loader .circular {
  animation: loader-rotate 2s linear infinite;
  position: absolute;
  left:  calc(50% - 24px);
  top:  calc(50% - 24px);
  display: block;
  transform: rotate(0deg);
}

#ftco-loader .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: loader-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes loader-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -136px;
  }
}
.previousButton, .nextButton {
	position: absolute;
	top: 50%;
	align-items: center;
	z-index: 10;
	height: 50px;
	width: 50px;
	fill:$primary;
	padding: 5px;

   
}


.previousButton {
left: 0;
-webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
	  transform: rotate(180deg) translateY(calc(50% + 0px));
	  
}



.nextButton {
right: 0;
}

.nextButton:hover ,.previousButton:hover {
background-color: $primary;
fill: $white;
border-radius: 50%;
}

  .slider{
	position: relative;
	height: 700px;
	.Video{
		width: 100%;
		height: 800px;
		position: absolute;
		object-fit: fill;

		@media  (max-width: 600px) {
		  object-fit:cover;
		  height: 100%;
	  }
	}

}

.clint{
	.swiper-slide{
		img{
			width: 200px;
			height: 100px;
		}
	}
}
.mr-100{
	margin: 100px auto;
}
.mr-50{
	margin: 50px auto;
}
.clint{
	.swiper-slide{
		img{
			width: 200px;
			height: 100px;
		}
	}
}


.Homeslide{
	.pagination {
		position: absolute !important;
		width: 100%;
		text-align: center;
		right: 0;
		padding: 0 !important;
		bottom: 30px;
		z-index: 999;
	  }
	  
	  .pagination__item {
		cursor: pointer;
		display: inline-block;
		white-space: nowrap;
		font-size: 0;
		width: 10px;
		height: 10px;
		border: 1px solid #fff;
		margin: 0 5px;
		
		transition: .2s ease-in-out;
		&.is-current,
		&:hover {
		  background-color: #fff;
		}
	  }
	  
	  .container {
		position: relative;
		margin: 0 auto;
	  }
	  
	  @media (max-width: 699px) {
		.container {
		  padding-right: 40px;
		  padding-left: 40px;
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 1599px) {
		.container {
		  padding-right: 7.5rem;
		  padding-left: 7.5rem;
		  max-width: 140rem;
		}
	  }
	  
	  @media (min-width: 1600px) {
		.container {
		  padding-right: 9.5625rem;
		  padding-left: 9.5625rem;
		  max-width: 144.125rem;
		}
	  }
	  
	  .background-absolute {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-position: center;
		background-size: cover;
	  }
	  
	  .slideshow {
		position: relative;
		color: #fff;
		overflow: hidden;
		  min-height: 600px;
		  height: 100vh;
		h3,
		h1,
		h2 {
		  /*font-family: 'Montserrat', sans-serif;*/
		  font-family: 'Lato', sans-serif;
		  text-transform: uppercase;
		  letter-spacing: 0.5em;
		  line-height: 2em;
		}
		h1 {
		  font-size: 44px;
		  font-weight: 800;
		}
	  }
	  
	  .slideshow__slide {
		visibility: hidden;
		
		transition: visibility 0s 1.7s;
		&.is-current {
		  visibility: visible;
		  
		  transition-delay: 0s;
		}
	  }
	  
	  @media (max-width: 699px) {
		.slideshow .slideshow__slide {
		  position: absolute;
		  top: 0;
		  right: 0;
		  bottom: 0;
		  left: 0;
		}
	  }
	  
	  @media (min-width: 700px) {
		.slideshow .slideshow__slide {
		  position: absolute;
		  top: 0;
		  right: 0;
		  bottom: 0;
		  left: 0;
		}
	  }
	  
	  .slideshow__slide-background-load-wrap {
		transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1);
		transform: translate3d(0, 100%, 0);
		overflow: hidden;
	  }
	  
	  .is-animated .slideshow__slide-background-load-wrap {
		
		transform: translate3d(0, 0, 0);
		
		transition-delay: 0s;
	  }
	  
	  .slideshow__slide {
		&.is-prev .slideshow__slide-background-parallax,
		&.is-next .slideshow__slide-background-parallax,
		&.is-prev-section .slideshow__slide-background-parallax,
		&.is-next-section .slideshow__slide-background-parallax {
		  
		  transform: none !important;
		}
	  }
	  
	  .slideshow__slide-background-load {
		transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1);
		
		transform: translate3d(0, -50%, 0);
	  }
	  
	  .is-animated .slideshow__slide-background-load {
		
		transform: translate3d(0, 0, 0);
	  }
	  
	  .slideshow__slide-background-wrap {
		transition: transform 1.2s cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
		transform: translate3d(0, 0, 0);
	  }
	  
	  .slideshow__slide {
		&.is-prev .slideshow__slide-background-wrap {
		  
		  transform: translate3d(0, -100%, 0);
		}
		&.is-next .slideshow__slide-background-wrap {
		  
		  transform: translate3d(0, 100%, 0);
		}
		&.is-prev-section .slideshow__slide-background-wrap {
		  
		  transform: translate3d(0, -100%, 0);
		  
		  transition: none;
		}
		&.is-next-section .slideshow__slide-background-wrap {
		  
		  transform: translate3d(0, 100%, 0);
		  
		  transition: none;
		}
	  }
	  
	  .slideshow__slide-background {
		transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1) 1.5s;
		
		transform: scale(1);
		overflow: hidden;
	  }
	  
	  .slideshow__slide {
		&.is-prev .slideshow__slide-background,
		&.is-next .slideshow__slide-background {
		  
		  transform: scale(0.5);
		  
		  transition-delay: 0s;
		}
		&.is-prev-section .slideshow__slide-background,
		&.is-next-section .slideshow__slide-background {
		  
		  transform: scale(0.5);
		  
		  transition-delay: 0s;
		  
		  transition: none;
		}
	  }
	  
	  .slideshow__slide-image-wrap {
		transition: transform 1.2s cubic-bezier(0.4, 0, 0.2, 1) 0.6s;
		
		transform: translate3d(0, 0, 0);
	  }
	  
	  .slideshow__slide.is-prev .slideshow__slide-image-wrap {
		
		transform: translate3d(0, 50%, 0);
	  }
	  
	  .slideshow__slide-image {
		transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1) 1.5s;
		transform: scale(1);
	  }
	  
	  .slideshow__slide {
		&.is-prev .slideshow__slide-image,
		&.is-next .slideshow__slide-image {
		  
		  transform: scale(1.25);
		  
		  transition-delay: 0s;
		}
		&.is-prev-section .slideshow__slide-image,
		&.is-next-section .slideshow__slide-image {
		  
		  transform: scale(1.25);
		  
		  transition-delay: 0s;
		  
		  transition: none;
		}
	  }
	  
	  .slideshow__slide-image {
		&::before,
		&::after {
		  content: "";
		  position: absolute;
		  top: 0;
		  right: 0;
		  bottom: 0;
		  left: 0;
		  opacity: 0.35;
		}
		&::before {
		  background-color: rgba(255, 255, 255, 0.26);
		}
		&::after {
		  background: linear-gradient(to top, transparent 0%, #fff 20%);
		}
	  }
	  
	  .slideshow__slide {
		&.is-prev .slideshow_container,
		&.is-next .slideshow_container,
		&.is-prev-section .slideshow_container,
		&.is-next-section .slideshow_container {
		  
		  transform: none !important;
		}
	  }
	  
	  .slideshow__slide-caption-text {
		position: relative;
		height: 100%;
		padding-top: 10%;
		transition: transform 1.2s cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
		
		transform: translate3d(0, 0, 0);
	  }
	  
	  .slideshow__slide {
		&.is-prev .slideshow__slide-caption-text {
		  
		  transform: translate3d(-100%, -50%, 0);
		}
		&.is-next .slideshow__slide-caption-text {
		  
		  transform: translate3d(-100%, 10%, 0);
		}
		&.is-prev-section .slideshow__slide-caption-text {
		  
		  transform: translate3d(-100%, -50%, 0);
		  
		  transition: none;
		}
		&.is-next-section .slideshow__slide-caption-text {
		  
		  transform: translate3d(-100%, 10%, 0);
		  
		  transition: none;
		}
	  }
	  
	  .slideshow__slide-caption {
		position: relative;
		height: 100%;
		
		transform: translate3d(-100%, 10%, 0);
		transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
	  }
	  
	  .is-animated .slideshow__slide-caption {
		
		transform: translate3d(0, 0, 0);
	  }
	  
	  .slideshow__slide-caption-title {
		line-height: 1;
		&.-full {
		  width: 100%;
		}
	  }
	  
	  @media (max-height: 500px) {
		.slideshow__slide-caption-title {
		  margin-bottom: 0 !important;
		}
	  }
	  
	  @media (max-width: 699px) {
		.slideshow__slide-caption-title {
		  font-size: 40px;
		  margin-bottom: 150px;
		}
		.slideshow.-full .slideshow__slide-caption-title {
		  margin-bottom: 30px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.slideshow__slide-caption-title {
		  font-size: 5.625rem;
		  margin-bottom: 1.25rem;
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 749px) {
		.slideshow__slide-caption-title {
		  font-size: 4.375rem;
		}
	  }
	  
	  @media (min-width: 1600px) {
		.slideshow__slide-caption-title {
		  font-size: 6.25rem;
		}
	  }
	  
	  .slideshow__slide-caption-subtitle {
		display: inline-block;
		padding: 1.875rem 0;
		&.-load {
		  transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
		  
		  transform: translate3d(0, 3.75rem, 0);
		}
	  }
	  
	  .is-animated .slideshow__slide-caption-subtitle.-load {
		
		transform: translate3d(0, 0, 0);
	  }
	  
	  body {
		&[data-route-option="prev-section"] .slideshow__slide-caption-subtitle.-load,
		&[data-route-option="next-section"] .slideshow__slide-caption-subtitle.-load {
		  
		  transform: translate3d(0, 0, 0);
		}
	  }
	  
	  .slideshow__slide-caption-subtitle-label {
		transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
		
		transform: translateZ(0);
		display: inline-block;
	  }
	  
	  .o-hsub.-link:hover .slideshow__slide-caption-subtitle-label,
	  .o-hsub-wrap:hover .slideshow__slide-caption-subtitle-label {
		
		transform: translateX(20px);
	  }
	  
	  /* OLD */
	  
	  .c-header-home_heading {
		line-height: 1;
		&.-full {
		  width: 100%;
		}
	  }
	  
	  @media (max-height: 500px) {
		.c-header-home_heading {
		  margin-bottom: 0 !important;
		}
	  }
	  
	  @media (max-width: 699px) {
		.c-header-home_heading {
		  font-size: 40px;
		  margin-bottom: 150px;
		}
		.c-header-home.-full .c-header-home_heading {
		  margin-bottom: 30px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.c-header-home_heading {
		  font-size: 5.625rem;
		  margin-bottom: 1.25rem;
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 749px) {
		.c-header-home_heading {
		  font-size: 4.375rem;
		}
	  }
	  
	  @media (min-width: 1600px) {
		.c-header-home_heading {
		  font-size: 6.25rem;
		}
	  }
	  
	  .c-header-home_subheading {
		display: inline-block;
		padding: 1.875rem 0;
		&.-load {
		  transition: transform 0.9s cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
		  transform: translate3d(0, 3.75rem, 0);
		}
	  }
	  
	  .is-loaded .c-header-home_subheading.-load {
		
		transform: translate3d(0, 0, 0);
	  }
	  
	  body {
		&[data-route-option="prev-section"] .c-header-home_subheading.-load,
		&[data-route-option="next-section"] .c-header-home_subheading.-load {
		  
		  transform: translate3d(0, 0, 0);
		}
	  }
	  
	  .c-header-home_footer {
		z-index: 3;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
	  }
	  
	  .c-header-home_controls,
	  .c-header-home_buttons {
		margin-left: 0;
		letter-spacing: normal;
		font-size: 0;
		transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
		
		transform: translate3d(0, 100%, 0);
	  }
	  
	  @media (max-width: 699px) {
		.c-header-home_controls,
		.c-header-home_buttons {
		  padding-bottom: 40px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.c-header-home_controls,
		.c-header-home_buttons {
		  padding-bottom: 5.625rem;
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 749px) {
		.c-header-home_controls,
		.c-header-home_buttons {
		  padding-bottom: 3.75rem;
		}
	  }
	  
	  .is-loaded {
		.c-header-home_controls,
		.c-header-home_buttons {
		  
		  transform: translate3d(0, 0, 0);
		}
	  }
	  
	  body {
		&[data-route-option="prev-section"] {
		  .c-header-home_controls,
		  .c-header-home_buttons {
			
			transform: translate3d(0, 0, 0);
		  }
		}
		&[data-route-option="next-section"] {
		  .c-header-home_controls,
		  .c-header-home_buttons {
			
			transform: translate3d(0, 0, 0);
		  }
		}
	  }
	  
	  .c-header-home_controls {
		
		transition-delay: 0.65s;
	  }
	  
	  @media (min-width: 700px) {
		.c-header-home_controls {
		  float: left;
		}
	  }
	  
	  .c-header-home_buttons {
		
		transition-delay: 0.75s;
	  }
	  
	  @media (max-width: 699px) {
		.c-header-home_buttons {
		  margin-left: -20px;
		  margin-right: -20px;
		}
	  }
	  
	  @media (min-width: 1000px) {
		.c-header-home_buttons {
		  float: right;
		}
	  }
	  
	  @media (max-width: 699px) {
		.c-header-home_button {
		  width: 50% !important;
		}
	  }
	  
	  @media (min-width: 700px) {
		.c-header-home_button {
		  width: 15.625rem;
		}
	  }
	  
	  button,
	  .c-header-filters_button,
	  .o-button {
		display: inline-block;
		overflow: visible;
		margin: 0;
		padding: 0;
		outline: 0;
		border: 0;
		background: none;
		color: inherit;
		vertical-align: middle;
		text-align: center;
		text-decoration: none;
		text-transform: none;
		font: inherit;
		line-height: normal;
		cursor: pointer;
		
		
		
		user-select: none;
	  }
	  
	  button:hover,
	  .c-header-filters_button:hover,
	  .o-button:hover {
		text-decoration: none;
	  }
	  
	  @media (min-width: 1200px) {
		body {
		  height: 100%;
		}
	  }
	  
	  @media (min-width: 1200px) {
		.o-scroll {
		  height: 100%;
		}
	  }
	  
	  ::-moz-selection {
		background: #0084c0;
		color: #fff;
	  }
	  
	  img,
	  svg {
		max-width: 100%;
	  }
	  
	  a,
	  .o-link {
		color: #1a0dab;
		
		transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	  }
	  
	  a:hover,
	  .o-link:hover {
		color: #13097c;
	  }
	  
	  a.-normal,
	  .o-link.-normal {
		color: currentColor;
		text-decoration: none;
	  }
	  
	  a.-normal:hover,
	  .o-link.-normal:hover {
		text-decoration: underline;
	  }
	  
	  a.-blue:hover,
	  .o-link.-blue:hover {
		text-decoration: none;
		color: #0084c0;
	  }
	  
	  a.-hover,
	  .o-link.-hover {
		position: relative;
		text-decoration: none;
		color: #fff;
	  }
	  
	  a.-hover::after,
	  .o-link.-hover::after {
		content: "";
		position: absolute;
		bottom: -1px;
		right: 0;
		left: 0;
		border-bottom: 1px solid;
		
		transform: scaleX(0);
		transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
		
		transform-origin: center left;
	  }
	  
	  a.-hover:hover::after,
	  .o-link.-hover:hover::after {
		
		transform: scaleX(1);
	  }
	  
	  p {
		margin: 0;
	  }
	  
	  .o-wrap {
		overflow: hidden;
	  }
	  
	  .o-page.-anim {
		
		transform: translate3d(0, 9.375rem, 0);
		transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
	  }
	  
	  .is-loaded .o-page.-anim {
		
		transform: translate3d(0, 0, 0);
	  }
	  
	  .o-barba,
	  .o-barba_container {
		height: 100%;
	  }
	  
	  strong {
		font-weight: 700;
	  }
	  
	  .js-parallax {
		
		transform: translateZ(0);
		will-change: transform;
	  }
	  
	  .scroll-content {
		overflow: hidden;
	  }
	  
	  .o-blockquote.-nomargin {
		margin: 0;
	  }
	  
	  .o-action-link {
		display: block;
		padding-top: 12.8125rem;
		padding-bottom: 7.5rem;
		text-align: center;
		text-decoration: none;
		font-weight: 700;
		&:hover {
		  color: #fff;
		}
	  }
	  
	  @media (max-width: 699px) {
		.o-action-link {
		  font-size: 40px;
		  padding-top: 120px;
		}
	  }
	  
	  @media (max-width: 1199px) {
		.o-action-link {
		  color: #1e1e22;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-action-link {
		  font-size: 5.625rem;
		}
	  }
	  
	  @media (min-width: 1200px) {
		.o-action-link {
		  color: #fff;
		}
	  }
	  
	  .o-action-link_label {
		display: inline-block;
		position: relative;
		&::after {
		  content: "";
		  position: absolute;
		  right: 0;
		  bottom: 0;
		  left: 0;
		  border-bottom: 0.1875rem solid;
		  
		  transform: scaleX(0);
		  
		  transform-origin: center left;
		  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
		}
	  }
	  
	  .o-action-link:hover .o-action-link_label::after {
		
		transform: scaleX(1);
	  }
	  
	  .o-h,
	  h1,
	  .o-h1,
	  h2,
	  .o-h2,
	  h3,
	  .o-h3,
	  h4,
	  .o-h4,
	  h5,
	  .o-h5,
	  h6,
	  .o-h6 {
		font-weight: 700;
		margin-top: 0;
		line-height: 1.1;
	  }
	  
	  @media (max-width: 699px) {
		h1,
		.o-h1 {
		  font-size: 26px;
		}
	  }
	  
	  @media (min-width: 700px) {
		h1,
		.o-h1 {
		  font-size: 60px;
		}
	  }
	  
	  @media (min-width: 1600px) {
		h1,
		.o-h1 {
		  font-size: 4.375rem;
		}
	  }
	  
	  @media (max-width: 1599px) {
		h2,
		.o-h2 {
		  font-size: 1.5625rem;
		}
	  }
	  
	  @media (min-width: 1600px) {
		h2,
		.o-h2 {
		  font-size: 2.25rem;
		}
	  }
	  
	  h3,
	  .o-h3 {
		font-size: 1.5625rem;
	  }
	  
	  h4,
	  .o-h4 {
		font-size: 1rem;
	  }
	  
	  h5,
	  .o-h5 {
		font-size: 0.8125rem;
	  }
	  
	  h6,
	  .o-h6 {
		font-size: 0.6875rem;
	  }
	  
	  .o-hsub {
		font-size: 0.75rem;
		padding: 1.25rem 0;
		display: inline-block;
		text-transform: uppercase;
		letter-spacing: 0.12em;
		font-weight: 500;
		&::before {
		  content: "";
		  display: inline-block;
		  vertical-align: middle;
		  border-bottom: 1px solid;
		  width: 1.5rem;
		  background-color: #1e1e22;
		  margin-right: 1.125rem;
		  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
		  
		  transform-origin: center left;
		}
		&.-link {
		  color: #fff;
		  text-decoration: none;
		  &:hover::before {
			
			transform: scaleX(1.5);
		  }
		}
	  }
	  
	  .o-hsub-wrap:hover .o-hsub.-link::before {
		
		transform: scaleX(1.5);
	  }
	  
	  .o-hsub {
		&.-link.-dark {
		  color: #1e1e22;
		  &:hover {
			color: #1e1e22;
		  }
		}
		&.-h {
		  vertical-align: middle;
		}
	  }
	  
	  @media (max-width: 699px) {
		.o-hsub.-h {
		  display: block;
		  margin-top: 20px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-hsub.-h {
		  margin-left: 2.5rem;
		}
	  }
	  
	  .o-hsub_label {
		transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
		transform: translateZ(0);
		display: inline-block;
	  }
	  
	  .o-hsub.-link:hover .o-hsub_label,
	  .o-hsub-wrap:hover .o-hsub_label {
		
		transform: translateX(20px);
	  }
	  
	  .is-loaded .o-loader {
		visibility: hidden;
		
		transition-delay: 0.6s;
	  }
	  
	  .o-container {
		position: relative;
		margin: 0 auto;
	  }
	  
	  @media (max-width: 699px) {
		.o-container {
		  padding-right: 40px;
		  padding-left: 40px;
		  &.-small {
			padding-right: 20px;
			padding-left: 20px;
		  }
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 1599px) {
		.o-container {
		  padding-right: 7.5rem;
		  padding-left: 7.5rem;
		  max-width: 140rem;
		}
	  }
	  
	  @media (min-width: 1600px) {
		.o-container {
		  padding-right: 9.5625rem;
		  padding-left: 9.5625rem;
		  max-width: 144.125rem;
		}
	  }
	  
	  .o-section {
		position: relative;
		&.-offset {
		  margin-top: -9.375rem;
		  background-color: #f6f6f6;
		}
		&.-padding {
		  padding-top: 7.5rem;
		  padding-bottom: 7.5rem;
		}
		&.-padding-top {
		  padding-top: 7.5rem;
		}
		&.-left {
		  margin-right: 15rem;
		}
		&.-right {
		  margin-left: 15rem;
		}
		&.-left-large {
		  margin-right: 22.5rem;
		}
		&.-right.-padding {
		  padding-left: 9.5625rem;
		}
	  }
	  
	  @media (max-width: 699px) {
		.o-section.-bottom {
		  padding-bottom: 60px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-section.-bottom {
		  padding-bottom: 7.5rem;
		}
	  }
	  
	  .o-section_image {
		position: relative;
		overflow: hidden;
		&.-small {
		  padding-bottom: 57.144%;
		}
		&.-large {
		  padding-bottom: 55%;
		}
		&.-padding-left {
		  margin-left: 7.5rem;
		}
		&.-left {
		  margin-right: 15rem;
		}
		&.-right {
		  margin-left: 15rem;
		}
		img {
		  width: 100%;
		}
	  }
	  
	  @media (max-width: 1599px) {
		.o-section_image.-left {
		  margin-left: -7.5rem;
		}
	  }
	  
	  @media (min-width: 1600px) {
		.o-section_image.-left {
		  margin-left: -9.5625rem;
		}
	  }
	  
	  @media (max-width: 1599px) {
		.o-section_image.-right {
		  margin-right: -7.5rem;
		}
	  }
	  
	  @media (min-width: 1600px) {
		.o-section_image.-right {
		  margin-right: -9.5625rem;
		}
	  }
	  
	  .o-grid {
		margin-left: 0;
		letter-spacing: normal;
		font-size: 0;
		&.-margin {
		  margin-left: -3.75rem;
		}
	  }
	  
	  .o-grid_item {
		display: inline-block;
		padding-left: 0;
		width: 100%;
		vertical-align: top;
		font-size: 1rem;
	  }
	  
	  @media (max-width: 699px) {
		.o-grid_item.-button {
		  width: 100%;
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 999px) {
		.o-grid_item.-button {
		  margin-bottom: 60px;
		}
	  }
	  
	  @media (min-width: 1000px) {
		.o-grid_item.-button {
		  width: 18.75rem;
		}
	  }
	  
	  @media (max-width: 699px) {
		.o-grid_item.-button-content {
		  margin-bottom: 30px;
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 999px) {
		.o-grid_item.-button-content {
		  margin-bottom: 60px;
		}
	  }
	  
	  @media (min-width: 1000px) {
		.o-grid_item.-button-content {
		  width: calc(100% - 18.75rem);
		}
	  }
	  
	  .o-grid.-margin .o-grid_item {
		padding-left: 3.75rem;
	  }
	  
	  @media (min-width: 700px) {
		.o-grid_item.-half {
		  width: 50%;
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 1199px) {
		.o-grid_item.-half.-large {
		  width: 100%;
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 999px) {
		.o-grid_item.-half.-medium {
		  width: 100%;
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 1199px) {
		.o-grid_item.-third {
		  width: 50%;
		}
	  }
	  
	  @media (min-width: 1200px) {
		.o-grid_item.-third {
		  width: 33.3333333333%;
		}
	  }
	  
	  .o-form {
		padding-bottom: 11.25rem;
	  }
	  
	  @media (max-width: 699px) {
		.o-form_item {
		  margin-bottom: 35px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-form_item {
		  margin-bottom: 2.9375rem;
		}
	  }
	  
	  .o-form_fieldset {
		padding: 0;
		margin: 0;
		border: 0;
	  }
	  
	  @media (max-width: 699px) {
		.o-form_fieldset {
		  margin-bottom: 20px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-form_fieldset {
		  margin-bottom: 3.75rem;
		}
	  }
	  
	  .o-form_button {
		text-align: right;
	  }
	  
	  .o-label {
		display: block;
		height: 100%;
		color: #b3b3b3;
		letter-spacing: 0.12em;
		text-transform: uppercase;
		padding: 0.875rem;
		
		transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	  }
	  
	  @media (max-width: 699px) {
		.o-label {
		  font-size: 9px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-label {
		  font-size: 0.5625rem;
		}
	  }
	  
	  .o-input-wrap .o-label {
		position: absolute;
		bottom: 0;
		left: 0;
		transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	  }
	  
	  .o-input:focus~.o-label,
	  .o-select:focus~.o-label,
	  .o-textarea:focus~.o-label,
	  .o-label.is-active {
		
		transform: translateY(-1.875rem);
	  }
	  
	  .o-input.has-error~.o-label {
		color: #cc3d3d;
	  }
	  
	  .has-error {
		&.o-select~.o-label,
		&.o-textarea~.o-label {
		  color: #cc3d3d;
		}
	  }
	  
	  .o-input-wrap {
		position: relative;
	  }
	  
	  .o-input,
	  .o-select,
	  .o-textarea {
		padding: 0.875rem;
		background-color: transparent;
		border-bottom: 1px solid #b3b3b3;
		
		transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	  }
	  
	  @media (max-width: 699px) {
		.o-input,
		.o-select,
		.o-textarea {
		  font-size: 14px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-input,
		.o-select,
		.o-textarea {
		  font-size: 0.875rem;
		}
	  }
	  
	  .o-input::-webkit-input-placeholder,
	  .o-select::-webkit-input-placeholder,
	  .o-textarea::-webkit-input-placeholder,
	  .o-input:-ms-input-placeholder,
	  .o-select:-ms-input-placeholder,
	  .o-textarea:-ms-input-placeholder,
	  .o-input::-ms-input-placeholder,
	  .o-select::-ms-input-placeholder,
	  .o-textarea::-ms-input-placeholder,
	  .o-input::placeholder,
	  .o-select::placeholder,
	  .o-textarea::placeholder {
		color: #b3b3b3;
	  }
	  
	  .o-input.-search {
		background-color: transparent;
		color: #fff;
		font-weight: 700;
		border-bottom: none;
	  }
	  
	  .-search {
		&.o-select,
		&.o-textarea {
		  background-color: transparent;
		  color: #fff;
		  font-weight: 700;
		  border-bottom: none;
		}
	  }
	  
	  @media (max-width: 699px) {
		.o-input.-search {
		  font-size: 26px;
		}
		.-search {
		  &.o-select,
		  &.o-textarea {
			font-size: 26px;
		  }
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-input.-search {
		  font-size: 3.75rem;
		}
		.-search {
		  &.o-select,
		  &.o-textarea {
			font-size: 3.75rem;
		  }
		}
	  }
	  
	  .o-input.-search::-webkit-input-placeholder {
		color: #000;
	  }
	  
	  .-search {
		&.o-select::-webkit-input-placeholder,
		&.o-textarea::-webkit-input-placeholder {
		  color: #000;
		}
	  }
	  
	  .o-input.-search:-ms-input-placeholder {
		color: #000;
	  }
	  
	  .-search {
		&.o-select:-ms-input-placeholder,
		&.o-textarea:-ms-input-placeholder {
		  color: #000;
		}
	  }
	  
	  .o-input.-search::-ms-input-placeholder {
		color: #000;
	  }
	  
	  .-search {
		&.o-select::-ms-input-placeholder,
		&.o-textarea::-ms-input-placeholder {
		  color: #000;
		}
	  }
	  
	  .o-input.-search::placeholder {
		color: #000;
	  }
	  
	  .-search {
		&.o-select::placeholder,
		&.o-textarea::placeholder {
		  color: #000;
		}
	  }
	  
	  .-mobile {
		.o-input.-search {
		  font-size: 26px;
		  padding: 0;
		}
		.-search {
		  &.o-select,
		  &.o-textarea {
			font-size: 26px;
			padding: 0;
		  }
		}
	  }
	  
	  .o-input.-search.-light {
		color: #1e1e22;
	  }
	  
	  .-search.-light {
		&.o-select,
		&.o-textarea {
		  color: #1e1e22;
		}
	  }
	  
	  .o-input.-search.-light::-webkit-input-placeholder {
		color: #b3b3b3;
	  }
	  
	  .-search.-light {
		&.o-select::-webkit-input-placeholder,
		&.o-textarea::-webkit-input-placeholder {
		  color: #b3b3b3;
		}
	  }
	  
	  .o-input.-search.-light:-ms-input-placeholder {
		color: #b3b3b3;
	  }
	  
	  .-search.-light {
		&.o-select:-ms-input-placeholder,
		&.o-textarea:-ms-input-placeholder {
		  color: #b3b3b3;
		}
	  }
	  
	  .o-input.-search.-light::-ms-input-placeholder {
		color: #b3b3b3;
	  }
	  
	  .-search.-light {
		&.o-select::-ms-input-placeholder,
		&.o-textarea::-ms-input-placeholder {
		  color: #b3b3b3;
		}
	  }
	  
	  .o-input.-search.-light::placeholder {
		color: #b3b3b3;
	  }
	  
	  .-search.-light {
		&.o-select::placeholder,
		&.o-textarea::placeholder {
		  color: #b3b3b3;
		}
	  }
	  
	  .o-input.has-error {
		border-color: #cc3d3d;
	  }
	  
	  .has-error {
		&.o-select,
		&.o-textarea {
		  border-color: #cc3d3d;
		}
	  }
	  
	  .o-input:focus,
	  .o-select:focus,
	  .o-textarea:focus {
		outline: none;
	  }
	  
	  .o-input-line {
		display: block;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		border-bottom: 1px solid #1e1e22;
		
		transform: scaleX(0);
		transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
		
		transform-origin: center left;
	  }
	  
	  .o-input:focus~.o-input-line,
	  .o-select:focus~.o-input-line,
	  .o-textarea:focus~.o-input-line {
		
		transform: scaleX(1);
	  }
	  
	  .o-input-lines {
		&::before,
		&::after {
		  content: "";
		  position: absolute;
		  bottom: 0;
		  border-right: 1px solid #b3b3b3;
		  height: 0.375rem;
		  
		  transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
		}
	  }
	  
	  .o-input.has-error~.o-input-lines::before {
		border-color: #cc3d3d;
	  }
	  
	  .has-error {
		&.o-select~.o-input-lines::before,
		&.o-textarea~.o-input-lines::before {
		  border-color: #cc3d3d;
		}
	  }
	  
	  .o-input.has-error~.o-input-lines::after {
		border-color: #cc3d3d;
	  }
	  
	  .has-error {
		&.o-select~.o-input-lines::after,
		&.o-textarea~.o-input-lines::after {
		  border-color: #cc3d3d;
		}
	  }
	  
	  .o-input-lines {
		&::before {
		  left: 0;
		  
		  transition-delay: 0.3s;
		}
		&::after {
		  right: 0;
		}
	  }
	  
	  .o-input:focus~.o-input-lines::before,
	  .o-select:focus~.o-input-lines::before,
	  .o-textarea:focus~.o-input-lines::before,
	  .o-input:focus~.o-input-lines::after,
	  .o-select:focus~.o-input-lines::after,
	  .o-textarea:focus~.o-input-lines::after {
		border-color: #1e1e22;
	  }
	  
	  .o-input:focus~.o-input-lines::before,
	  .o-select:focus~.o-input-lines::before,
	  .o-textarea:focus~.o-input-lines::before {
		
		transition-delay: 0s;
	  }
	  
	  .o-input:focus~.o-input-lines::after,
	  .o-select:focus~.o-input-lines::after,
	  .o-textarea:focus~.o-input-lines::after {
		
		transition-delay: 0.3s;
	  }
	  
	  .o-checkbox,
	  .o-radio {
		position: absolute;
		width: 0;
		opacity: 0;
	  }
	  
	  .o-checkbox:checked+.o-checkbox-label::after,
	  .o-radio:checked+.o-checkbox-label::after,
	  .o-checkbox:checked+.o-radio-label::after,
	  .o-radio:checked+.o-radio-label::after {
		
		transform: scale(1);
	  }
	  
	  .o-checkbox-label,
	  .o-radio-label {
		position: relative;
		display: inline-block;
		margin-right: 0.5em;
		line-height: 1.4;
		margin-right: 4.0625rem;
		cursor: pointer;
		padding-top: 0.125rem;
	  }
	  
	  @media (max-width: 699px) {
		.o-checkbox-label,
		.o-radio-label {
		  font-size: 12px;
		  padding-left: 27px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-checkbox-label,
		.o-radio-label {
		  font-size: 0.875rem;
		  padding-left: 1.1875rem;
		}
	  }
	  
	  .o-checkbox-label.-uppsercase,
	  .-uppsercase.o-radio-label {
		text-transform: uppercase;
	  }
	  
	  .o-checkbox-label::before,
	  .o-radio-label::before,
	  .o-checkbox-label::after,
	  .o-radio-label::after {
		position: absolute;
		top: 50%;
		left: 0;
		display: inline-block;
		padding: 0;
		content: "";
		border: 1px solid;
		
		transition: border-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	  }
	  
	  @media (max-width: 699px) {
		.o-checkbox-label::before,
		.o-radio-label::before,
		.o-checkbox-label::after,
		.o-radio-label::after {
		  width: 12px;
		  height: 12px;
		  margin-top: -6px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-checkbox-label::before,
		.o-radio-label::before,
		.o-checkbox-label::after,
		.o-radio-label::after {
		  margin-top: -0.28125rem;
		  width: 0.5625rem;
		  height: 0.5625rem;
		}
	  }
	  
	  .o-checkbox-label::after,
	  .o-radio-label::after {
		width: 0;
		height: 0;
		border-style: solid;
		border-color: #1e1e22 transparent transparent transparent;
		
		transform: scale(0);
		
		transform-origin: top left;
		transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
	  }
	  
	  @media (max-width: 699px) {
		.o-checkbox-label::after,
		.o-radio-label::after {
		  border-width: 12px 12px 0 0;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-checkbox-label::after,
		.o-radio-label::after {
		  border-width: 0.5625rem 0.5625rem 0 0;
		}
	  }
	  
	  .o-checkbox-label.has-error::before,
	  .has-error.o-radio-label::before {
		border-color: #cc3d3d;
	  }
	  
	  .o-checkbox-label_text {
		display: inline-block;
		transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
		
		transform: translateZ(0);
	  }
	  
	  .o-checkbox-label:hover .o-checkbox-label_text {
		
		transform: translateX(0.3125rem);
	  }
	  
	  .o-radio-label {
		&:hover .o-checkbox-label_text {
		  
		  transform: translateX(0.3125rem);
		}
		&::before {
		  border-radius: 50%;
		  width: 12px;
		  height: 12px;
		}
		&::after {
		  border-radius: 50%;
		  width: 12px;
		  height: 12px;
		  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20enable-background%3D%22new%200%200%2013%2013%22%20xml%3Aspace%3D%22preserve%22%3E%3Ccircle%20fill%3D%22%23424242%22%20cx%3D%226.5%22%20cy%3D%226.5%22%20r%3D%226.5%22%2F%3E%3C%2Fsvg%3E");
		  background-size: 6px;
		  background-position: center;
		  background-repeat: no-repeat;
		  
		  transform: scale(0);
		  
		  transform-origin: center;
		  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
		  border: none;
		}
	  }
	  
	  .o-radio:checked+.o-radio-label::after {
		
		transform: scale(1);
	  }
	  
	  .o-select {
		position: relative;
		z-index: 1;
		padding-right: 2.5rem;
		&:focus {
		  border-bottom-color: #1e1e22;
		}
	  }
	  
	  .o-select-wrap {
		position: relative;
		&::after {
		  position: absolute;
		  top: 0;
		  right: 0;
		  bottom: 0;
		  z-index: 2;
		  width: 2.5rem;
		  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2211.3%22%20viewBox%3D%220%200%2013%2011.3%22%20enable-background%3D%22new%200%200%2013%2011.3%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%23b3b3b3%22%20points%3D%226.5%2011.3%203.3%205.6%200%200%206.5%200%2013%200%209.8%205.6%20%22%2F%3E%3C%2Fsvg%3E");
		  background-position: center;
		  background-size: 10px;
		  background-repeat: no-repeat;
		  content: "";
		  pointer-events: none;
		}
	  }
	  
	  .o-textarea-wrap {
		position: relative;
	  }
	  
	  .o-textarea {
		min-height: 9.375rem;
	  }
	  
	  .o-button {
		position: relative;
		display: inline-block;
		text-align: center;
		border: 1px solid #1e1e22;
		white-space: nowrap;
		font-size: 0;
		
		transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1), color 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
		&:before {
		  display: inline-block;
		  height: 100%;
		  content: "";
		  vertical-align: middle;
		}
		>* {
		  display: inline-block;
		  vertical-align: middle;
		  white-space: normal;
		  font-size: 1rem;
		}
		&::after {
		  content: "";
		  position: absolute;
		  top: 0;
		  right: 0;
		  bottom: 0;
		  left: 0;
		  background-color: #1e1e22;
		  
		  transform: scaleX(0);
		  
		  transform-origin: center left;
		  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
		}
		&:hover {
		  color: #fff;
		  
		  transition-delay: 0s;
		  &::after {
			
			transform: scaleX(1);
			
			transition-delay: 0s;
		  }
		}
		&.-left::after {
		  
		  transform-origin: center right;
		}
		&.-white {
		  border-color: #fff;
		  &::after {
			background-color: #fff;
		  }
		  &:hover {
			color: #000;
		  }
		}
		&.-form {
		  width: 11.25rem;
		  &:focus {
			color: #fff;
			
			transition-delay: 0s;
			&::after {
			  
			  transform: scaleX(1);
			  
			  transition-delay: 0s;
			}
		  }
		}
		&.-square {
		  padding: 0;
		}
	  }
	  
	  @media (max-width: 699px) {
		.o-button {
		  height: 60px;
		  padding: 0 20px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-button {
		  height: 3.75rem;
		  padding: 0 1.875rem;
		}
	  }
	  
	  @media (min-width: 1200px) {
		.o-button.-anim {
		  border-color: transparent;
		}
	  }
	  
	  @media (max-width: 699px) {
		.o-button.-width {
		  width: 100%;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-button.-width {
		  width: 15rem;
		}
	  }
	  
	  @media (max-width: 699px) {
		.o-button.-square {
		  width: 60px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-button.-square {
		  width: 3.75rem;
		}
	  }
	  
	  .o-button-group .o-button+.o-button {
		border-left: none;
	  }
	  
	  @media (max-width: 699px) {
		.o-button.-padding {
		  padding: 1.25rem;
		}
	  }
	  
	  @media (min-width: 700px) {
		.o-button.-padding {
		  padding: 1.25rem 2.5rem;
		}
	  }
	  
	  .o-button_label {
		display: inline-block;
		position: relative;
		transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
		transform: translateX(0);
		line-height: 1.4;
		z-index: 2;
		text-transform: uppercase;
		letter-spacing: 0.12em;
		font-weight: 500;
		font-size: 0.75rem;
	  }
	  
	  .o-button {
		&:hover .o-button_label {
		  
		  transform: translateX(0.5rem);
		  
		  transition-delay: 0.075s;
		}
		&.-left:hover .o-button_label {
		  
		  transform: translateX(-0.5rem);
		}
		&.-square:hover .o-button_label {
		  
		  transform: translateX(0.375rem);
		}
		&.-left.-square:hover .o-button_label {
		  
		  transform: translateX(-0.375rem);
		}
	  }
	  
	  .o-button-group {
		margin-left: 0;
		letter-spacing: normal;
		font-size: 0;
	  }
	  
	  .o-button_icon {
		position: relative;
		width: 1.0rem;
		height: 1.0rem;
		fill: #000;
		
		transition: fill 0.45s cubic-bezier(0.4, 0, 0.2, 1) 0.075s;
	  }
	  
	  .o-button {
		&.-white .o-button_icon {
		  fill: #fff;
		}
		&:hover .o-button_icon {
		  fill: #f6f6f6;
		  
		  transition-delay: 0s;
		}
		&.-white:hover .o-button_icon {
		  fill: #1e1e22;
		}
	  }
	  
	  .o-button_line {
		&::before,
		&::after {
		  content: "";
		  position: absolute;
		  background-color: #1e1e22;
		}
	  }
	  
	  .is-mobile .o-button_line {
		&::before,
		&::after {
		  display: none;
		}
	  }
	  
	  .o-button_line {
		&::before {
		  width: 1px;
		  top: 0;
		  bottom: 0;
		  
		  transform: scaleY(0);
		  transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
		}
		&::after {
		  height: 1px;
		  right: 0;
		  left: 0;
		  
		  transform: scaleX(0);
		  transition: transform 0.45s cubic-bezier(0.4, 0, 0.2, 1);
		}
		&:first-of-type {
		  &::before {
			left: 0;
			
			transform-origin: center bottom;
		  }
		  &::after {
			top: 0;
			
			transform-origin: center left;
		  }
		}
		&:last-of-type {
		  &::before {
			right: 0;
			
			transform-origin: center top;
		  }
		  &::after {
			bottom: 0;
			
			transform-origin: center right;
		  }
		}
	  }
	  
	  .o-button.is-inview .o-button_line {
		&::before {
		  
		  transform: scaleY(1);
		}
		&::after {
		  
		  transform: scaleX(1);
		}
		&:first-of-type {
		  &::before {
			
			transition-delay: 1.15s;
		  }
		  &::after {
			
			transition-delay: 0.1s;
		  }
		}
		&:last-of-type {
		  &::before {
			
			transition-delay: 0.55s;
		  }
		  &::after {
			
			transition-delay: 0.7s;
		  }
		}
	  }
	  
	  .c-header-home_footer {
		z-index: 3;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
	  }
	  
	  .c-header-home_controls,
	  .c-header-home_buttons {
		margin-left: 0;
		letter-spacing: normal;
		font-size: 0;
		transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
		
		transform: translate3d(0, 100%, 0);
	  }
	  
	  @media (max-width: 699px) {
		.c-header-home_controls,
		.c-header-home_buttons {
		  padding-bottom: 40px;
		}
	  }
	  
	  @media (min-width: 700px) {
		.c-header-home_controls,
		.c-header-home_buttons {
		  padding-bottom: 5.625rem;
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 749px) {
		.c-header-home_controls,
		.c-header-home_buttons {
		  padding-bottom: 3.75rem;
		}
	  }
	  
	  .is-loaded {
		.c-header-home_controls,
		.c-header-home_buttons {
		  
		  transform: translate3d(0, 0, 0);
		}
	  }
	  
	  body {
		&[data-route-option="prev-section"] {
		  .c-header-home_controls,
		  .c-header-home_buttons {
			
			transform: translate3d(0, 0, 0);
		  }
		}
		&[data-route-option="next-section"] {
		  .c-header-home_controls,
		  .c-header-home_buttons {
			
			transform: translate3d(0, 0, 0);
		  }
		}
	  }
	  
	  .c-header-home_controls {
		
		transition-delay: 0.65s;
	  }
	  
	  @media (min-width: 700px) {
		.c-header-home_controls {
		  float: left;
		}
	  }
	  
	  .c-header-home_buttons {
		
		transition-delay: 0.75s;
	  }
	  
	  @media (max-width: 699px) {
		.c-header-home_buttons {
		  margin-left: -20px;
		  margin-right: -20px;
		}
	  }
	  
	  @media (min-width: 1000px) {
		.c-header-home_buttons {
		  float: right;
		}
	  }
	  
	  @media (max-width: 699px) {
		.c-header-home_button {
		  width: 50% !important;
		}
	  }
	  
	  @media (min-width: 700px) {
		.c-header-home_button {
		  width: 15.625rem;
		}
	  }
	  
	  .slideshow__slide-image.background-absolute {
		width: 60%;
		left: 40%;
	  }
	  
	  h1.slideshow__slide-caption-title {
		padding-left: 98px;
		padding-right: 98px;
		  color:black;
	  }
	  
	  .o-hsub.-link {
		padding-left: 98px;
		padding-right: 98px;
		color: #000;
		text-decoration: none;
	  }
	  
	  button.slider-more-button {
		width: 16rem;
		height: 7rem;
		position: absolute;
		bottom: 0;
		right: 0;
		background-color: #fcc865;
		color: #000;
		text-transform: uppercase;
		transition: opacity 700ms cubic-bezier(0.8, 0, 0.55, 0.94) 470ms, transform 700ms cubic-bezier(0.8, 0, 0.55, 0.94) 470ms;
		transform: rotateZ(-20deg) translate3d(-20vh, 20vh, 0);
	  }
	  
	  .is-animated .slider-more-button {
		transform: rotateZ(0deg) translate3d(0, 0, 0);
	  }
	  
	  p.slideshow__slide-caption-content {
		padding-left: 98px;
		padding-right: 98px;
		box-sizing: border-box;
		color: rgba(0, 0, 0, 0.7);
		cursor: pointer;
		display: block;
		font-family: Plain, sans-serif;
		font-size: 14px;
		font-weight: 200;
		max-height: 218px;
		letter-spacing: 0.28px;
		line-height: 28px;
		margin-top: 20px;
		opacity: 0;
		text-size-adjust: 100%;
		transition: opacity 700ms cubic-bezier(0.8, 0, 0.55, 0.94), transform 700ms cubic-bezier(0.8, 0, 0.55, 0.94);
		transform: translate3d(-100px, 0, 0);
		max-width: 40%;
		width: 40%;
	  }
	  
	  .slideshow__slide.is-current p.slideshow__slide-caption-content {
		opacity: 1;
		transition: opacity 700ms cubic-bezier(0.8, 0, 0.55, 0.94) 1470ms, transform 700ms cubic-bezier(0.8, 0, 0.55, 0.94) 1470ms;
		transform: translate3d(0, 0, 0);
	  }
	  
	  span.slideshow__slide-caption-subtitle-label {
		font-weight: 800;
		font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
	  }
	  
	  .side-nav {
		display: none;
	  }
	  
	  @media (min-width: 1024px) {
		.side-nav {
		  display: block;
		  left: 0;
		  z-index: 2;
		  width: 100vh;
		  height: 5vw;
		  transform-origin: top;
		  display: flex;
		  align-items: center;
		  justify-content: center;
		  
		  transform: translate3d(-100%, 10%, 0);
		  transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
		}
		.is-animated {
		  .side-nav {
			transition: opacity 700ms cubic-bezier(0.8, 0, 0.55, 0.94) 470ms, transform 700ms cubic-bezier(0.8, 0, 0.55, 0.94) 470ms;
			transform: rotateZ(-90deg) translate3d(-390px, -50vh, 0);
		  }
		  .slideshow__slide-caption {
			
		  }
		}
		.side-nav {
		  opacity: 1;
		  transition: opacity 700ms cubic-bezier(0.8, 0, 0.55, 0.94) 1470ms, transform 700ms cubic-bezier(0.8, 0, 0.55, 0.94) 1470ms;
		  ul {
			list-style: none;
			margin: 0 0;
			padding-top: 0.3vw;
		  }
		  li {
			display: inline-block;
			line-height: 0.9em;
			margin: 0 15px;
			padding-bottom: 8px;
			border-bottom: 1px solid transparent;
			cursor: pointer;
			transition: opacity 400ms cubic-bezier(0.8, 0, 0.55, 0.94), border-color 400ms cubic-bezier(0.8, 0, 0.55, 0.94);
		  }
		  .label {
			font-size: 11px;
			letter-spacing: 0.18em;
			text-transform: uppercase;
			opacity: 0.3;
			margin: 0 0;
			color: #0a0c0d;
			font-weight: 800;
			font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
		  }
		  &[data-view="cover"] li[data-view="cover"] {
			opacity: 1;
			border-bottom: 1px solid #000;
		  }
		}
		.slideshow__slide {
		  &.is-prev .side-nav {
			
			transform: translate3d(-100%, -50%, 0);
		  }
		  &.is-next .side-nav {
			
			transform: translate3d(-100%, 10%, 0);
		  }
		  &.is-prev-section .side-nav {
			
			transform: translate3d(-100%, -50%, 0);
			
			transition: none;
		  }
		  &.is-next-section .side-nav {
			
			transform: translate3d(-100%, 10%, 0);
			
			transition: none;
		  }
		}
	  }
	  
	  /*end slider show*/
	  
	  
	  /*Floating button*/
	  
	  .floating-btn {
		font-family: 'Roboto Thin', sans-serif;
		border: none;
		border-width: 0px;
		margin: 0;
		overflow: hidden;
		border: none;
		outline: none;
		color: white;
		font-size: 20px;
		background: #F6EFE3;
		position: relative;
		padding: 14px;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		box-shadow: 0 12px 42px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.24);
		
		
		
		
		transition: .3s;
		margin: 0.5em;
		&:hover {
		  background: #d2cbbf;
		  cursor: pointer;
		  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		}
	  }
	  
	  .js-parallax.is-inview {
		width: 100px;
	  }
	  
	  button .floating-btn:focus {
		outline: 0px;
		outline: 0px;
	  }
	  
	  .c-header-home_controls.-nomobile.o-button-group {
		margin-left: 30%;
	  }
	  
	  .divide {
		margin-top: 50px;
		margin-bottom: 50px;
	  }
	  
	  .ripple {
		overflow: hidden;
	  }
	  
	  .ripple-effect {
		position: absolute;
		border-radius: 50%;
		width: 50px;
		height: 50px;
		background: white;
		animation: ripple-animation 2s;
	  }
	  
	  .ripple>.o-button_icon {
		top: 1px;
	  }
	  
	  @keyframes ripple-animation {
		from {
		  transform: scale(1);
		  opacity: 0.4;
		}
		to {
		  transform: scale(100);
		  opacity: 0;
		}
	  }
	  
	  /* End Floating Button */
	  
	  /* nav bar*/
	  
	  .navbar-container {
		z-index: 1000;
		background-color: #fff;
	  }
	  
	  @media (max-width: 699px) {
		.container {
		  padding-right: 0px;
		  padding-left: 0px;
		}
	  }
	  
	  @media (min-width: 700px) and (max-width: 1599px) {
		.container {
		  padding-right: 0rem;
		  padding-left: 0rem;
		  max-width: 140rem;
		}
	  }
	  
}
.slide h1 {
	transition: all 0.3s ease;
	-webkit-transform: translateY(-20px);
					transform: translateY(-20px);
	opacity: 0;
}

.slide button {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide p {
	transition: all 0.3s ease;
	-webkit-transform: translateY(20px);
					transform: translateY(20px);
	opacity: 0;
}

.slide section * {
	transition: all 0.3s ease;
}

.slide section img {
	-webkit-transform: translateX(-10px);
					transform: translateX(-10px);
	opacity: 0;
}

.slide section span {
	-webkit-transform: translateY(-10px);
					transform: translateY(-10px);
	opacity: 0;
}

.slide section span strong {
	-webkit-transform: translateY(10px);
					transform: translateY(10px);
	opacity: 0;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p,
.slide.animateIn.previous section *,
.slide.current section *,
.slide.animateIn.next section * {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	-webkit-transition-delay: .9s;
					transition-delay: .9s;
					opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
	-webkit-transition-delay: 1.1s;
					transition-delay: 1.1s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
	-webkit-transition-delay: 1.3s;
					transition-delay: 1.3s;
}

.slide.animateIn.previous section img,
.slide.animateIn.next section img {
	-webkit-transition-delay: 1.3s;
					transition-delay: 1.3s;
}

.slide.animateIn.previous section span,
.slide.animateIn.next section span {
	-webkit-transition-delay: 1.4s;
					transition-delay: 1.4s;
}

.slide.animateIn.previous section span strong,
.slide.animateIn.next section span strong {
	-webkit-transition-delay: 1.5s;
					transition-delay: 1.5s;
}

.slide.animateOut h1 {
	-webkit-transition-delay: .3s;
					transition-delay: .3s;
}

.slide.animateOut p {
	-webkit-transition-delay: .2s;
					transition-delay: .2s;
}

.slide.animateOut section span {
	-webkit-transition-delay: .1s;
					transition-delay: .1s;
}

.slide.animateOut section span strong {
	-webkit-transition-delay: 0s;
					transition-delay: 0s;
}

.slider-wrapper {
	position: relative;
	height: 700px;
	overflow: hidden;
	background-color: $white;
	.container-flu{
		width: 95%;
		margin: auto;
	}
  }
  
  .slide {
	height: 70vh;
	background-size: cover !important;
  }
  .slider-text {
	height: 750px;
	justify-content: space-between;
	z-index: 0;
	@include media-breakpoint-down(sm){
		text-align: center;
	}
	.one-third{
		width: 60%;
		height: 100%;
		z-index: 1;
		@include media-breakpoint-down(md){
			width: 100%;
			z-index: 0;
		}
	}
	.one-forth{
		width: 40%;

		@include media-breakpoint-down(md){
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			padding: 1em;
		}
	}
	.subheading{
		color: $black;
		font-weight: 700;
	}
	.text{
		position: relative;
		z-index: 1;
		
		@include media-breakpoint-down(md){
			background: rgba(255,255,255,.3);
			padding: 2em;
		}
	}
	h1 {
		font-size: 60px;
		color: $primary;
		line-height: 1.5;
		font-weight: 900;
		// letter-spacing: 1px;
		// text-transform: uppercase;
		a{
			color: $black;
		}
		@include media-breakpoint-down(md) {
			color: $primary;
		}
		@include media-breakpoint-down(sm) {
			font-size: 30px;
			// color: $pri;
		}
	}
	p {
		font-size: 18px;
		line-height: 1.5;
		font-weight: 300;
		color: $black;

		&.sub-p{
			font-weight: 400;
		}
		strong{
			font-weight: 700;
			a{
				color: $color3;
			}
		}
	}

	h3.vr{
		writing-mode: vertical-lr;
	  transform: rotate(0deg);
	  text-orientation: sideways;
	  text-align:center;
	  text-orientation: sideways;
	  margin: 0rem;
	  position: absolute;
	  top: 0;
	  right: 20px;
	  height: 100%;
	  text-transform: uppercase;
	  font-weight: 400;
	  color: $semone;
	  letter-spacing: 4px;
	  font-size: 14px;
	  z-index: 1;
	  @include media-breakpoint-down(md) {
		right: -20px;
	}
	@include media-breakpoint-down(sm) {
		right: -20px;
	}
	
	}
}
  .slider-item {
	position: relative;
	z-index: 0;
	@include media-breakpoint-down(lg){
		background-position: center center !important;
	}
	.slider-text {
		height: 750px;
		z-index: 0;
		justify-content: space-between;
		@include media-breakpoint-down(sm){
			text-align: center;
		}
		.one-third{
			width: 60%;
			position: relative;
			z-index: -1;
			height: 100%;
			right: 0px;
			// left: 100%;
			@include media-breakpoint-down(md){
				width: 100%;
				z-index: 0;
			}
		}
		.one-forth{
			width: 40%;
			position: relative;
			@include media-breakpoint-down(md){
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1;
				padding: 1em;
			}
		}
	
		.text{
			position: relative;
			z-index: 1;
			@include media-breakpoint-up(lg){
				margin-right: -250px;
			}
			@include media-breakpoint-down(md){
				background: rgba(255,255,255,.3);
				padding: 2em;
			}
		}
		h1 {
			font-size: 60px;
			color: $primary;
			line-height: 1.5;
			font-weight: 900;
			// letter-spacing: 1px;
			// text-transform: uppercase;
			
			@include media-breakpoint-down(md) {
				color: $primary;
			}
			@include media-breakpoint-down(sm) {
				font-size: 30px;
				// color: $pri;
			}
		}
		p {
			font-size: 18px;
			line-height: 1.5;
			font-weight: 300;
			color: rgba(0,0,0,.8);
			@include media-breakpoint-down(md) {
				// color: rgba(255,255,255,.8);

			}
			&.sub-p{
				font-weight: 400;
			}
			strong{
				font-weight: 700;
				a{
					color: $color3;
				}
			}
		}

		h3.vr{
			writing-mode: vertical-lr;
		  transform: rotate(0deg);
		  text-orientation: sideways;
		  text-align:center;
		  text-orientation: sideways;
		  margin: 0rem;
		  position: absolute;
		  top: 0;
		  right: -40px;
		  height: 100%;
		  text-transform: uppercase;
		  font-weight: 400;
		  letter-spacing: 4px;
		  font-size: 14px;
		  z-index: 1;
		  @include media-breakpoint-down(lg){
			  opacity: 0;
		  }
		}
	}
}
  .slide::before {
	  content: '';
	  display: block;
	  position: absolute;
	  width: 100%;
	  height: 100%;
	
	  bottom: 0;
	  left: 0;
  }
  

  @media (max-height: 500px) {
	.slider-wrapper, .slide {
	  height: calc(100vh - 75px);
	}    
  }
  
  @media (max-width: 640px) {
	.slider-wrapper, .slide {
	  height: calc(80vh - 75px);
	}    
  }
  
  @media (max-height: 600px) {
	.slider-content .inner h1 {
	  font-size: 32px;
	}    
  }
  
  @media (max-width: 640px) {
	.slider-content .inner h1 {
	  font-size: 32px;
	}
  }
  .patternback{
	background-color: $primary;
		top: 100%;
		width: 100%;
		height: 15px;
		left: 0px;
		position: absolute;
		.patternwhite{
			background-color: $color3;
			width: 100%;
			height: 8%;
			position: absolute;
			top: -8%;

		}
	.pattern{
		background-image: url("../Assets/logo/bat2.png");
		width: 100%;
		height: 15px;
		position: absolute;
		
	}
  }
  .slider{
	position: relative;
	height: 700px;
	.Video{
		width: 100%;
		height: 800px;
		position: absolute;
		object-fit: fill;

		@media  (max-width: 600px) {
		  object-fit:cover;
		  height: 100%;
	  }
	}

}

.clint{
	.swiper-slide{
		img{
			width: 200px;
			height: 100px;
		}
	}
}
.mr-100{
	margin: 100px auto;
}
.mr-50{
	margin: 50px auto;
}
.media {
	.ftco-icon {
		width: 100px;
		span {
			color: $primary;
		}
	}
}